<template>
    <div class="college animate__animated animate__fadeInDown">
        <a-collapse accordion expand-icon-position="right" default-active-key="1" :bordered="false">
            <template #expandIcon="props">
                <a-icon type="right" :rotate="props.isActive ? 90 : 0" />
            </template>
            <a-collapse-panel v-for="c,i in colleges" :key="'college'+i" :header="c.title" :style="customStyle">
                <p class="content" v-html="c.content"></p>
            </a-collapse-panel>

        </a-collapse>
        
    </div>
</template>
<script>
// import Countdown from "../components/Countdown.vue";
export default {
    name: "College",
    data() {
        return {
            customStyle:'background: #f7f7f7;margin-bottom: 24px;border: 0;overflow: hidden',
            colleges:[]
        };
    },
    components: { },
    mounted() {
        let _this = this
        this.$http.call(this.$http.api.colleges).then(function(res){
            // console.log(res.data.data)
            _this.colleges = res.data.data;
        },function(res){
            console.log('something goes wrong...');
            console.log(res);
        }).then(()=>{
            
        });
        
    },
    methods: {
        
        
    },
};
</script>
<style lang="less" scoped>
.college {
    width:100%;
    text-align: left;
    .content{
        word-wrap: normal;
        word-break: break-all;
    }
}
</style>
